.App {
  text-align: center;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  
}

.App-header {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

#maincontainer {
  height: 100vh;
}

#inputcontainer {
  width: 50%;
  float: left;

  text-align: left !important; 
  box-shadow: none !important;
  background-color: white !important;
}

#outputcontainer {
  width: 50%;
  float: right;
}


.outerboxsl {
  padding: 10px;
}